import styled from "@emotion/styled";
import Confetti from "react-confetti";
import { useState, useEffect } from "react";
import React from 'react'
import { Link } from 'gatsby'

import Layout from "../../../components/Layout";
import Share from "../../../components/Share";

export default () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, 100);
  });

  return (
    <Layout>
      <section className='hero is-primary is-bold is-large'>
        <div className='hero-body has-text-centered'>
          <Confetti width={width} height={height} numberOfPieces={450} />
          <h1 className='title'>Payment received.</h1>
          <h3>
            Thank you from the bottom of our hearts for helping to provide PPE.
          </h3>
          <Share
            title={"Recently became aware of PPE4ALL, a group trying to bridge the supply and demand of PPE in NYC. Check them out! "}
            slug={""}
            excerpt={""}
          />
          <Link to='/map/'><button>Add yourself to the PPE4ALL Map</button></Link>
        </div>
      </section>
    </Layout>
  )
};
